import React from 'react';
import {Link} from 'react-router-dom';
import './Slider.css';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';


SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);



const Blockchain = () =>{
  return (
        <div>
          
    <Swiper
      spaceBetween={50}
      slidesPerView={2}
      centeredSlides = {true}
      navigation
      autoplay = {true}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      
      breakpoints = {{
        320: {
          width: 320,
          slidesPerView: 1,
          spaceBetween: 40,
          centeredSlides: true,
         scrollbar : {draggable: true }
         
        },
        480: {
          width: 480,
          slidesPerView: 1,
          spaceBetween: 40,
         scrollbar : {draggable: true }

        },
        640: {
          width: 640,
          slidesPerView: 1,
          spaceBetween: 50,
         scrollbar : {draggable: true }
        },
      }}
    >
    
  <SwiperSlide>
   <div className="container">
  <div className="row">
    <div className="col-lg-6">
      <div className="common-heading text-l">
        <span>
        Binance</span>
        <p>
        Dive into the Binance Smart Chain universe now with Ejisco. With years of experience and industry experience, we are a pioneer in providing BSC blockchain based solutions that leverage the speed Elevate your project with our BSC expertise!
        </p>
       
      </div>
    </div>
    <div className="col-lg-6 lead-intro-"><img src="/asset/images/sliders/binance.png" alt="image" className="img-fluid" /></div>
  </div>
</div>
</SwiperSlide>


<SwiperSlide>
   <div className="container">
  <div className="row">
    <div className="col-lg-6">
      <div className="common-heading text-l">

      <span>
        TRON</span>
        <p>
        Unlock the potential of the TRON blockchain with us. We are dedicated to harnessing the power of TRON for creating cutting-edge decentralized apps and services. Join us in revolutionizing industries through the high-speed, low-cost ecosystem of TRON.

        </p>
       
      </div>
    </div>
    <div className="col-lg-6 lead-intro-"><img src="/asset/images/sliders/tron.png" alt="image" className="img-fluid" /></div>
  </div>
</div>
</SwiperSlide>


<SwiperSlide>
   <div className="container">
  <div className="row">
    <div className="col-lg-6">
      <div className="common-heading text-l">
        <span>
          FANTOM
        </span>
        <p>
        Experience our Fantom blockchain solutions with us that redefines speed, scalability, and security for decentralized applications. Our ecosystem empowers developers to create efficient and innovative solutions that make blockchain technology accessible to everyone. Join us on the journey to a faster and more efficient future with Fantom.
        </p>
       
      </div>
    </div>
    <div className="col-lg-6 lead-intro-"><img src="/asset/images/sliders/fantom.png" alt="image" className="img-fluid" /></div>
  </div>
</div>
</SwiperSlide>


<SwiperSlide>
   <div className="container">
  <div className="row">
    <div className="col-lg-6">
      <div className="common-heading text-l">
        <span>
            Solana
          </span>
        <p>
        With our proficiency in Solana blockchain technology, we are specialized in delivering Solana blockchain solutions with high-performance that redefine possibilities. Experience scalability and efficiency with Solana, powered by our expertise.
        </p>
       
      </div>
    </div>
    <div className="col-lg-6 lead-intro-"><img src="/asset/images/sliders/solana.png" alt="image" className="img-fluid" /></div>
  </div>
</div>
</SwiperSlide>


<SwiperSlide>
   <div className="container">
  <div className="row">
    <div className="col-lg-6">
      <div className="common-heading text-l">
        <span>
            Polygon
        </span>
        <p>
        Join us on the forefront of innovation and unlock the full potential of blockchain technology with Polygon. the world of decentralized applications with high-performance infrastructure that offers lightning-fast transactions and scalability.
        </p>
       
      </div>
    </div>
    <div className="col-lg-6 lead-intro-"><img src="/asset/images/sliders/polygon.png" alt="image" className="img-fluid" /></div>
  </div>
</div>
</SwiperSlide>

<SwiperSlide>
   <div className="container">
  <div className="row">
    <div className="col-lg-6">
      <div className="common-heading text-l">
        <span>
        Avalanche
        </span>
        <p>
        We furnish Avalanche blockchain services that powers applications unparalleled speed, security, and scalability. Our consensus protocol sets new standards for efficiency that enables developers to create robust and highly customizable blockchain networks. Join us in revolutionizing the decentralized landscape with Avalanche.        </p>
       
      </div>
    </div>
    <div className="col-lg-6 lead-intro-"><img src="/asset/images/sliders/avalanche.png" alt="image" className="img-fluid" /></div>
  </div>
</div>
</SwiperSlide>

<SwiperSlide>
   <div className="container">
  <div className="row">
    <div className="col-lg-6">
      <div className="common-heading text-l">
        <span>
            Binance Smart Chain
        </span>
        <p>
        Dive into the Binance Smart Chain universe now with us. With years of experience and industry experience, we are a pioneer in providing BSC blockchain based solutions that leverage the speed Elevate your project with our BSC expertise!</p>
       
      </div>
    </div>
    <div className="col-lg-6 lead-intro-"><img src="/asset/images/sliders/bsc.png" alt="image" className="img-fluid" /></div>
  </div>
</div>
</SwiperSlide>

<SwiperSlide>
   <div className="container">
  <div className="row">
    <div className="col-lg-6">
      <div className="common-heading text-l">
        <span>
            ETHEREUM
        </span>
        <p>
        Join us on the forefront of innovation and unlock the full potential of blockchain technology with Polygon. the world of decentralized applications with high-performance infrastructure that offers lightning-fast transactions and scalability.
        </p>
       
      </div>
    </div>
    <div className="col-lg-6 lead-intro-"><img src="/asset/images/sliders/eth.png" alt="image" className="img-fluid" /></div>
  </div>
</div>
</SwiperSlide>


<SwiperSlide>
   <div className="container">
  <div className="row">
    <div className="col-lg-6">
      <div className="common-heading text-l">
       
      <div className="-cta-btn mt70">
        <div className="free-cta-title v-center wow zoomInDown" data-wow-delay="1.8s">
          <Link to="/get-in-touch" className="btn-main bg-btn2 lnk">Inquire Now
          <i className="fas fa-chevron-right fa-icon" />
          <span className="circle" />
          </Link>
        </div>
      </div> 

      </div>
    </div>
  </div>
</div>
</SwiperSlide>


    </Swiper>
    </div>
  );
   
}

export default Blockchain;
