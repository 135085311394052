import React, { useState} from 'react';
import { AppstoreOutlined, LogoutOutlined, SettingOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import {Link} from 'react-router-dom';
import firebase from 'firebase';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom'
import HireDeveloper from '../../pages/contact/HireDeveloper';


// const { SubMenu, Item } = Menu;

const Header = () =>{
    const [current, setCurrent] = useState('home')
  const [menuOpen, setMenuOpen] = useState(false);

    let dispatch = useDispatch()
    let{ user} = useSelector((state) =>({ ...state }))
    let history = useHistory();


    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
    };
  
    const closeMenu = () => {
      setMenuOpen(false);
    };

    const handleClick = (e) =>{
        setCurrent(e.key)
        // console.log(e)
    }

    const logout = () =>{
      firebase.auth().signOut()
      dispatch({
        type: 'LOGOUT',
        payload: null
      });
      history.push('/login')
    }

    return (
      <div>
      {/* Desktop Header */}
      <header className="nav-bg-b main-header navfix fixed-top menu-white header-pr d-none d-lg-block">
        <div className="container-fluid m-pad">
          <div className="menu-header">
            <div className="dsk-logo">
              <Link className="nav-brand" to="/">
                <img src="/asset/images/logo.png" alt="Ejiscotechnologies" className="mega-white-logo" width={150} />
                <img src="/asset/images/logo.png" alt="Ejiscotechnologies" className="mega-darks-logo" width={150} />
              </Link>
            </div>

            <div className="custom-nav" role="navigation">
              <ul className="nav-list">
                <li className=""><Link to="/about-us" className="menu-links">About Us</Link></li>
                <li className="sbmenu">
                  <Link to="/services" className="menu-links">Services</Link>
                  <div className="nx-dropdown">
                    <div className="sub-menu-section">
                      <div className="container">
                        <div className="sub-menu-center-block">
                          <div className="sub-menu-column">
                            <ul>
                              <li><Link to="/webpps">Web Services Solution</Link></li>
                              <li><Link to="/mobileappdev">Mobile Application Solution</Link></li>
                              <li><Link to="/api-integration-development">API Integration Services</Link></li>
                              <li><Link to="/custom-software-development">Custom software development services</Link></li>
                            </ul>
                          </div>
                          <div className="sub-menu-column">
                            <ul>
                              <li><Link to="/network-solution">Network Solution</Link></li>
                              <li><a target="_blank" href="https://buynow247.store/">Security Surveillance Camera</a></li>
                              <li><Link to="/biometric-access-control">Biometric Access Control</Link></li>
                              <li><a target="_blank" href="https://buynow247.store/">Access Control Systems</a></li>
                            </ul>
                          </div>
                          <div className="sub-menu-column">
                            <ul>
                              <li><Link to="/services/green-energy-solution">Green Energy Solution</Link></li>
                              <li><a target="_blank" href="https://buynow247.store/">Solar Installation &amp; Supply</a></li>
                              <li><a target="_blank" href="https://buynow247.store/">Electronics &amp; Computer Supply</a></li>
                              <li><a target="_blank" href="https://buynow247.store/">Sound System Supply</a></li>
                            </ul>
                          </div>
                          <div className="sub-menu-column">
                            <ul>
                              <li><Link to="/software-testing">Software testing services</Link></li>
                              <li><Link to="/ecommerce-development">Ecommerce Development Services</Link></li>
                              <li><a href="/blockchain-development">Blockchain Development</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="sbmenu">
                  <a href="#" className="menu-links">Hire</a>
                  <div className="nx-dropdown">
                    <div className="sub-menu-section">
                      <div className="container">
                        <div className="sub-menu-center-block">
                          <div className="sub-menu-column">
                            <ul>
                              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire Angular Developer</a></li>
                              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire React Native Developer</a></li>
                              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire Flutter Developer</a></li>
                            </ul>
                          </div>
                          <div className="sub-menu-column">
                            <ul>
                              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire Nodejs Developer</a></li>
                              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire Mobile App Developer</a></li>
                              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire PHP Developer</a></li>
                            </ul>
                          </div>
                          <div className="sub-menu-column">
                            <ul>
                              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire Reactjs Developer</a></li>
                              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Dedicated Software Development Team</a></li>
                            </ul>
                          </div>
                          <div className="sub-menu-column">
                            <ul>
                              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire Dedicated Developer</a></li>
                              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire Python Developer</a></li>
                            </ul>
                          </div>
                          <div className="sub-menu-column">
                            <ul>
                              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire Ruby on Rails Developer</a></li>
                              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire ASP.NET Developer</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="sbmenu rpdropdown"><Link to="/how-it-works" className="menu-links">How it Works</Link></li>
                {/* <li className="sbmenu rpdropdown"><a href="#" className="menu-links">Case Study</a></li> */}
                {/* User Account Menu */}
                {user && (
                  <li className="sbmenu rpdropdown" key="SubMenu" icon={<SettingOutlined />}>
                    <a href="#" className="menu-links">Account</a>
                    <div className="nx-dropdown menu-dorpdown">
                      <div className="sub-menu-section">
                        <div className="sub-menu-center-block">
                          <div className="sub-menu-column smfull">
                            <ul>
                              <li><a href="#">{user.email && user.email.split('@')[0]}</a></li>
                              <li><a href="#">Profile</a></li>
                              <li><Link to="/skyadmin/dashboard">Dashboard</Link></li>
                              <li><a href="#" icon={<LogoutOutlined />} onClick={logout}>Logout</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>

            <div className="contact-show">
              <a href="#" className="btn-round- trngl btn-br bg-btn"><i className="fas fa-phone-alt" /></a>
              <div className="contact-inquiry">
                <div className="contact-info-">
                  <div className="contct-heading"> Contacts</div>
                  <div className="inquiry-card-nn hrbg">
                    <div className="title-inq-c">FOR NG LOCATION</div>
                    <ul><li className="mb0"><img src="/asset/images/flags/ng.png" alt="us office" className="flags-size" /> <a href="tel:234 916 777 7733">+234 916 777 7733</a></li></ul>
                  </div>
                  <div className="inquiry-card-nn">
                    <ul>
                      <li><a href="https://wa.me/2349167777733?text=Hello%2C%20I%20have%20a%20question%20about%20your%20services%2C%20can%20you%20please%20help%20me%20out%3F" target='_blank'><img src="/asset/images/flags/whatsapp.png" alt="us office" className="flags-size" /> +234 916 777 7733</a></li>
                      <li><i className="fab fa-skype" /><a href="skype:Ejisco?call">skype</a></li>
                      <li><i className="fas fa-envelope" /><a href="mailto:support@ejisco.com">support@ejisco.com</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <Link to="/get-in-touch" className="btn-br bg-btn3 btshad-b2 lnk">Get in Touch <span className="circle" /></Link>
          </div>
        </div>
      </header>

      {/* Mobile Header */}
      <header className="d-block d-lg-none">
        <div className="container-fluid">
          <div className="menu-header">
            <div className="dsk-logo">
              <Link className="nav-brand" to="/">
                <img src="/asset/images/logo.png" alt="Ejiscotechnologies" className="mega-white-logo" width={150} />
              </Link>
            </div>
            <div className="menu-toggle" onClick={toggleMenu}>
              <i className={`fas fa-bars ${menuOpen ? 'open' : ''}`} />
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Menu */}
      <div className={`mobile-menu ${menuOpen ? 'open' : ''} nav-list`}>
        <ul onClick={closeMenu} className='nav-list'>
          <li><Link to="/about-us" className="menu-links">About Us</Link></li>
          <li>
            <Link to="/services" className="menu-links">Services</Link>
            <ul>
              <li><Link to="/webpps">Web Services Solution</Link></li>
              <li><Link to="/mobileappdev">Mobile Application Solution</Link></li>
              <li><Link to="/api-integration-development">API Integration Services</Link></li>
              <li><Link to="/custom-software-development">Custom software development services</Link></li>
              <li><Link to="/network-solution">Network Solution</Link></li>
              <li><a target="_blank" href="https://buynow247.store/">Security Surveillance Camera</a></li>
              <li><Link to="/biometric-access-control">Biometric Access Control</Link></li>
              <li><a target="_blank" href="https://buynow247.store/">Access Control Systems</a></li>
              <li><Link to="/services/green-energy-solution">Green Energy Solution</Link></li>
              <li><a target="_blank" href="https://buynow247.store/">Solar Installation &amp; Supply</a></li>
              <li><a target="_blank" href="https://buynow247.store/">Electronics &amp; Computer Supply</a></li>
              <li><a target="_blank" href="https://buynow247.store/">Sound System Supply</a></li>
              <li><Link to="/software-testing">Software testing services</Link></li>
              <li><Link to="/ecommerce-development">Ecommerce Development Services</Link></li>
              <li><Link to="/blockchain-development">Blockchain Development</Link></li>
            </ul>
          </li>
          {/* <li>
            <a href="#">Hire</a>
            <ul>
              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire Angular Developer</a></li>
              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire React Native Developer</a></li>
              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire Flutter Developer</a></li>
              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire Nodejs Developer</a></li>
              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire Mobile App Developer</a></li>
              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire PHP Developer</a></li>
              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire Reactjs Developer</a></li>
              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Dedicated Software Development Team</a></li>
              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire Dedicated Developer</a></li>
              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire Python Developer</a></li>
              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire Ruby on Rails Developer</a></li>
              <li><a href="#" data-toggle="modal" data-target="#modalform-full">Hire ASP.NET Developer</a></li>
            </ul>
          </li> */}
          <li><Link to="/how-it-works">How it Works</Link></li>
          {/* User Account Menu */}
          {user && (
            <li key="SubMenu">
              <a href="#" className="menu-links">Account</a>
              <ul>
                <li><a href="#">{user.email && user.email.split('@')[0]}</a></li>
                <li><a href="#">Profile</a></li>
                <li><Link to="/skyadmin/dashboard">Dashboard</Link></li>
                <li><a href="#" icon={<LogoutOutlined />} onClick={logout}>Logout</a></li>
              </ul>
            </li>
          )}
        </ul>
      </div>

    </div>
    )

}



export default Header;
