
import React, { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
// import { message as toast } from 'antd'; // Import 'message' specifically for consistency
// Importing toastify module
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import emailjs from '@emailjs/browser';

toast.configure({ 
  position: toast.POSITION.TOP_CENTER,
  className: 'custom-toast-container', 
});


const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [service, setService] = useState(''); // State for service selection
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('Submit');

  const emailjsServiceID = 'service_8fvf28f'; // Replace with your actual service ID
  const emailjsTemplateID = 'template_x113x8q'; // Replace with your actual template ID
  const emailjsPublicKey = 'J4IVu0KsJFHtUlAxH'; // Replace with your actual public key

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    setLoading(true);
    setStatus('Sending...');
  
    const form = e.target; // Get the form element
  
    try {
      const response = await emailjs.sendForm(
        emailjsServiceID,
        emailjsTemplateID,
        form, // Pass the form element directly
        emailjsPublicKey
      );
  
      setStatus('Submit');
      toast('Email sent successfully!', { style: { backgroundColor: '#4864db', color: '#ffffff!important', top: 50% '!important',  } }); // Consistent styling
  
      // Optionally clear form fields or provide a "Message Sent" state
      // to handle successful submissions appropriately
  
    } catch (error) {
      setStatus('Submit');
      toast.error('Failed to send email. Please try again later.', { style: { backgroundColor: '#4864db', color: '#fff' } }); // Consistent styling
      console.error('Email sending error:', error);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div>
  {/*Breadcrumb Area*/}
  <section className="breadcrumb-area banner-6 section-nx">
    <div className="text-block">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 v-center">
            <div className="bread-inner">

              <div className="bread-title wow fadeInUp" data-wow-delay=".5s">
                <h2>Request A Quote</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*End Breadcrumb Area*/}
  {/*Start Enquire Form*/}
  <section className="contact-page pad-tb section-nx">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6 v-center">
          <div className="common-heading text-l">
                        <span> <h2 className="mt0 mb0">Get A Quick Estimate</h2></span>
            <p className="mb60 mt20">We will catch you as early as we receive the message</p>

            {loading ? (<LoadingOutlined style={{fontSize: '35px', color: '#08c'}}/>) 
                    : (<h4 align="center"></h4>)}
          </div>

     <div className="form-block">
      <form onSubmit={handleSubmit} data-toggle="validator">
      <div className="fieldsets row">
        <div className="fieldsets form-group">
          <label htmlFor="name">Name:</label>
          <input
            id="name"
            type="text"
            name="name"
            placeholder="Enter your name *"
            required="required"
            data-error="Name is required."
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="help-block with-errors" />
        </div>
        
        <div className="fieldsets form-group">
          <label htmlFor="email">Email:</label>
          <input
            id="email"
            type="email"
            name="email"
            placeholder="Enter your email *"
            required="required"
            data-error="Valid email is required."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="help-block with-errors" />
        </div>
        <div className="fieldsets form-group">
          <label htmlFor="phone">Phone number:</label>
          <input
            id="phone"
            type="text"
            name="phone"
            placeholder="Enter your phone number *"
            required="required"
            data-error="Phone number is required."
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <div className="help-block with-errors" />
        </div>
        <div className="fieldsets form-group">
          <label htmlFor="service">Service:</label>
          <select id="service" name="service" required="required" data-error="Specify your service.">
            <option value="">Select Service</option>
            <option value="web-development-service">Web Services</option>
            <option value="mobile-app-services">Mobile App</option>
            <option value="blockchain-services">Blockchain Development Services</option>
            <option value="electronic-services">Electro Services</option>
            <option value="support">Support</option>
            <option value="ticket">Ticket</option>
          </select>
          <div className="help-block with-errors" />
        </div>
        <div className="fieldsets form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            placeholder="Message for me *"
            rows={4}
            required="required"
            data-error="Please, leave us a message."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className="help-block with-errors" />
        </div>
        <div className="fieldsets form-group">
          <button type="submit" className="lnk btn-main bg-btn" disabled={loading}>
            {loading ? <LoadingOutlined style={{ fontSize: '20px', color: '#fff' }} spin /> : status}
            <span className="circle dkpr" />
          </button>
        </div> 
        </div>
    </form>

    </div>
        </div>
        <div className="col-lg-5 v-center">
          <div className="contact-details">
            <div className="contact-card wow fadeIn" data-wow-delay=".2s">
              <div className="info-card v-center">
                <span><i className="fas fa-phone-alt" /> Phone:</span>
                <div className="info-body">
                  <p>Assistance hours: Monday – Friday, 9 am to 5 pm</p>
                  <a href="tel:+234 916 777 7733">+234 916 777 7733</a>
                </div>
              </div>
            </div>

            <div className="email-card mt30 wow fadeIn" data-wow-delay=".5s">
              <div className="info-card v-center">
                <span><i className="fas fa-envelope" /> Email:</span>
                <div className="info-body">
                  <p>Our support team will get back to in 24-h during standard business hours.</p>
                  <a href="mailto:support@ejisco.com">support@ejisco.com
</a>
                </div>
              </div>
            </div>


            <div className="email-card mt30 wow fadeIn" data-wow-delay=".5s">
              <div className="info-card v-center">
                <span><i className="fas fa-phone" /> Whatsapp:</span>
                <div className="info-body">
                  {/* <p>Our support team will get back to in 24-h during standard business hours.</p> */}
                 <a href="https://wa.me/2349167777733?text=Hello%2C%20I%20have%20a%20question%20about%20your%20services%2C%20can%20you%20please%20help%20me%20out%3F" target='_blank'><img src="/asset/images/flags/whatsapp.png" alt="us office" className="flags-size" /> +234 916 777 7733</a>

                </div>
              </div>
            </div>

            <div className="skype-card mt30 wow fadeIn" data-wow-delay=".9s">
              <div className="info-card v-center">
                <span><i className="fab fa-skype" /> Skype:</span>
                <div className="info-body">
                  <p>We Are Online: Monday – Friday, 9 am to 5 pm</p>
                  <a href="skype:Ejisco?call">Ejiscotechnologies</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*End Enquire Form*/}
  {/*Start Location*/}

  {/*End Location*/}
</div>
  );
};

export default Contact;
